import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AccordionPage from '../components/accordion-content/accordion-page'
import termsAndConditions from '../assets/terms-and-conditions.json'
const TermsAndConditions = props => (
  <Layout location={props.location}>
    <SEO
      title="Terms and Conditions"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <AccordionPage content={termsAndConditions} title="Terms and Conditions" />
  </Layout>
)

export default TermsAndConditions
